exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-publications-index-tsx": () => import("./../../../src/pages/publications/index.tsx" /* webpackChunkName: "component---src-pages-publications-index-tsx" */),
  "component---src-pages-publications-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-contents-publications-a-mash-index-mdx": () => import("./../../../src/pages/publications/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/contents/publications/a-mash/index.mdx" /* webpackChunkName: "component---src-pages-publications-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-contents-publications-a-mash-index-mdx" */),
  "component---src-pages-publications-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-contents-publications-lanemate-index-mdx": () => import("./../../../src/pages/publications/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/contents/publications/lanemate/index.mdx" /* webpackChunkName: "component---src-pages-publications-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-contents-publications-lanemate-index-mdx" */),
  "component---src-pages-publications-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-contents-publications-lv-linker-index-mdx": () => import("./../../../src/pages/publications/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/contents/publications/lv-linker/index.mdx" /* webpackChunkName: "component---src-pages-publications-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-contents-publications-lv-linker-index-mdx" */),
  "component---src-pages-publications-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-contents-publications-modsandbox-index-mdx": () => import("./../../../src/pages/publications/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/contents/publications/modsandbox/index.mdx" /* webpackChunkName: "component---src-pages-publications-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-contents-publications-modsandbox-index-mdx" */),
  "component---src-pages-publications-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-contents-publications-mutiv-index-mdx": () => import("./../../../src/pages/publications/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/contents/publications/mutiv/index.mdx" /* webpackChunkName: "component---src-pages-publications-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-contents-publications-mutiv-index-mdx" */),
  "component---src-pages-publications-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-contents-publications-prevention-index-mdx": () => import("./../../../src/pages/publications/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/contents/publications/prevention/index.mdx" /* webpackChunkName: "component---src-pages-publications-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-contents-publications-prevention-index-mdx" */),
  "component---src-pages-publications-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-contents-publications-tomas-index-mdx": () => import("./../../../src/pages/publications/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/contents/publications/tomas/index.mdx" /* webpackChunkName: "component---src-pages-publications-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-contents-publications-tomas-index-mdx" */)
}

